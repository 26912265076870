import { TabPaneProps, TabsProps } from 'antd'
import { push } from 'connected-react-router'
import { getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { CasesTable, ONE_MINUTE_IN_MS } from 'features/cases-management/ui/cases-table/CasesTable'
import { DoctorsList } from 'features/cases-management/ui/doctor-list/DoctorsList'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { TableTabs, TTabItem } from 'shared/ui/tabs/TableTabs'

type TProps = {
  tabsItems: TTabItem[]
} & TabPaneProps &
  TabsProps

export const CasesTableTabs: React.FC<TProps> = ({ tabsItems }) => {
  /** Проверяем был ли распределён случай */
  const [isCaseAssigned, setIsCaseAssigned] = useState(false)
  const queryClient = useQueryClient()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const dispatch = useDispatch()
  const firstTabType = tabsItems[0].tabType
  const { tabsQueryParams } = useCaseManagementTabContext()
  const { filterParams, setFilterParams, visibleRowsCount } = useCaseManagementContext()
  const wsId = Number(useCurrentWorkspaceId())
  const tabType = currentTab ?? firstTabType
  const { isCaseRouting } = useSettingsAndUserRoles()

  // Запускаем refetch каждые 60 секунд для [Предыдущая, Текущая, Следующая] страниц, если есть
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchPages(
        queryClient,
        menuTab,
        {
          tab: tabType,
          ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting),
        },
        filterParams,
        wsId,
        visibleRowsCount,
      )
    }, ONE_MINUTE_IN_MS)

    return () => clearInterval(intervalId)
  }, [menuTab, tabType, tabsQueryParams, filterParams])

  // Очищаем кеш таблиц после дестроя
  useEffect(
    () => () => {
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === QUERY_TYPE.CASE_MANAGEMENT,
      })
    },
    [],
  )

  const onTabClick = (key: string) => {
    if (currentTab !== key) {
      /** Очищаем фильтры */
      setFilterParams({})
      /** Удаляем данные в табе при переходе */
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === QUERY_TYPE.CASE_MANAGEMENT,
      })
      queryClient.invalidateQueries([QUERY_TYPE.CASE_MANAGEMENT_CASES_COUNT, menuTab])
      dispatch(push(`/management/${menuTab}?tab=${key}`))
    }
  }

  useEffect(() => {
    if (tabType !== currentTab) {
      dispatch(push(`/management/${menuTab}?tab=${tabType}`))
    }
  }, [menuTab])

  return (
    <TableTabs tabsItems={tabsItems} onTabClick={onTabClick} activeTabType={tabType}>
      {menuTab === ECaseTableType.ROUTING && (
        <DoctorsList setIsCaseAssigned={setIsCaseAssigned} currentTab={currentTab} />
      )}
      <CasesTable setIsCaseAssigned={setIsCaseAssigned} isCaseAssigned={isCaseAssigned} tabType={tabType} />
    </TableTabs>
  )
}
