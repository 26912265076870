import Dexie from 'dexie'

/** Версия для базы */
export const version = 3

const db = new Dexie('database')

db.version(version)
  .stores({
    dictionaries: 'id, data, hash',
  })
  .upgrade(async (trx) => {
    console.log('Очистка базы данных при обновлении до новой версии')
    await trx.table('dictionaries').clear()
  })

export default db
