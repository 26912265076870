import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { SearchElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { css } from 'styled-components/macro'

interface CasesTableSearchProps {
  /** Строка поиска */
  query: string
  /** Колбек на изменения поля поиска */
  handleTableSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CasesTableSearch: FC<CasesTableSearchProps> = ({ handleTableSearch, query }) => {
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { isFiltersPicked } = useCaseManagementContext()

  const { t } = useTranslation()

  return (
    <StyledTableSearchElement
      isCaseRouting={isCaseRouting}
      isFiltersPicked={isFiltersPicked}
      data-testid="case-table-search-input"
      placeholder={`${t('Поиск по таблице')}...`}
      value={query}
      onChange={handleTableSearch}
    />
  )
}

export default CasesTableSearch

const defaultSearchCss = css<{ isCaseRouting: boolean }>`
  position: absolute;
  top: 7px;
  right: ${({ isCaseRouting }) => (isCaseRouting ? '120px' : '8px')};
`

const pickedSearchCss = css`
  margin-right: 10px;
  margin-bottom: 8px;
  position: static;
`

const StyledTableSearchElement = styled(SearchElement)<{ isFiltersPicked: boolean; isCaseRouting: boolean }>`
  width: 190px;
  height: 32px;
  z-index: 2;
  background-color: var(--color-border-1);
  ${({ isCaseRouting, isFiltersPicked }) => (isFiltersPicked ? pickedSearchCss : defaultSearchCss)};
`
