import db from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalizedItem, localizeDictionary } from 'shared/lib/common'
import { DictionaryItem } from 'types/IDictionary'

import SelectedTumorType from './ui/SelectedTumorType'
import TumorSelectElement from './ui/TumorSelectElement'

type Props = {
  onChange?: (morph?: DictionaryItem) => void
  value?: DictionaryItem
  placeholder?: string
  multiline?: boolean
}

export const TumorSelectContainer = ({ multiline = true, onChange, placeholder, value, ...props }: Props) => {
  const { i18n, t } = useTranslation(DictionaryNS.MORPHOLOGY_CODE)
  const dictionary = useLiveQuery(async () => {
    //@ts-ignore
    const dpt = await db.dictionaries.where('id').equals('MORPHOLOGY_CODE').toArray()
    if (!dpt.length) return null
    return dpt[0].data
  }) as TDict
  const { data, updatedAt } = { ...dictionary }

  const dictionaryLocales: DictionaryItem[] = localizeDictionary(data, DictionaryNS.MORPHOLOGY_CODE)

  const onSelect = useCallback(
    (id: string) => {
      if (dictionaryLocales) {
        const morph = dictionaryLocales.find((item) => item.shortName === id)
        if (onChange && morph) {
          onChange(morph)
        }
      }
    },
    [updatedAt],
  )
  const morphologyCodeLocalized = value && getLocalizedItem(value, DictionaryNS.ICD_10)

  if (morphologyCodeLocalized && multiline) {
    return (
      <SelectedTumorType
        morph={morphologyCodeLocalized}
        onDeleteMorph={() => onChange && onChange()}
        key={morphologyCodeLocalized.shortName}
      />
    )
  }
  return (
    <TumorSelectElement
      style={{
        width: '100%',
      }}
      placeholder={placeholder}
      onChange={onSelect as any}
      morphs={dictionaryLocales}
      morph={morphologyCodeLocalized}
      {...props}
    />
  )
}
