import { Input } from 'antd'
import db from 'app/indexedDB'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useLiveQuery } from 'dexie-react-hooks'
import { usePaginatedAtlasSlidesQuery } from 'entities/atlas'
import { DownloadAtlasSlides, PaginatedAtlasSlidesFilters } from 'entities/atlas/api/query'
import { TDict } from 'features/dictionaries'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { NoticesList } from 'features/notifications/NotificationsList'
import ResTable from 'pages/atlas/ui/ResTable'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import { SideRightPanelType, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { localizeDictionary } from 'shared/lib/common'
import { MINUS } from 'shared/text-constants'
import { ButtonElement, IconButtonElement, IconElement, MaskedDatePickerElement, RouterLink } from 'shared/ui/kit'
import { Column, dateRenderer, LabelImagePreviewPopup, previewPopupRenderer, stainRenderer } from 'shared/ui/table'
import { BadStatus, caseRenderer, GoodStatus } from 'shared/ui/table/ui/columnRenderers'
import styled from 'styled-components/macro'
import { IAtlasSlide, IAtlasValidationStatus } from 'types/IAtlasSlide'
import { DictionaryItem } from 'types/IDictionary'
import IUserRole from 'types/IUserRole'

const Content = styled.div`
  padding-bottom: 10px;
  flex: 3;
  width: 100%;
  position: relative;

  // avoid shift when no pagination
  & .ant-table:only-child {
    margin-top: 56px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 0 16px;
  width: 100%;

  @media (max-width: 1440px) {
    padding: 0;
    word-wrap: anywhere;
  }
`

const DateTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`

const DownloadBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  padding-top: 16px;
  z-index: 10;
`

const DownloadSubBlock = styled.div`
  display: flex;
  width: 340px;
  justify-content: space-between;
  z-index: 10;
`

const DateRangeLabel = styled.div`
  flex-basis: 20%;
`
const StyledLink = styled(Link)`
  color: var(--color-text-1);
`

const Actions = styled.div`
  float: right;
`
const StyledTable = styled(ResTable)`
  .ant-table table {
    width: 100% !important;
  }

  /* .ant-table-container {
    height: 84vh;
    overflow: auto;
  } */
`
type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

const AtlasRoute = ({ notificationButton }: Props) => {
  const { i18n, t } = useTranslation()
  const { atlasFilters, rightPanelType } = useTypedSelector((state) => state.viewerPage)
  const { data, isFetching } = usePaginatedAtlasSlidesQuery(atlasFilters)
  const { innerWidth: width } = window
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isAtlasDownloadAvailable = authorities?.includes(IUserRole.ROLE_SLIDE_DOWNLOAD_ATLAS)
  const dispatch = useDispatch()
  const [selected, setSelected] = useState<IAtlasSlide[]>()

  // const { query } = useStainsQuery()
  const dictionary = useLiveQuery(async () => {
    //@ts-ignore
    const dpt = await db.dictionaries.where('id').equals('STAIN').toArray()
    if (!dpt.length) return null
    return dpt[0].data
  }) as TDict
  const { data: stainsData, updatedAt } = { ...dictionary }
  const dictionaryLocales: DictionaryItem[] = localizeDictionary(stainsData, DictionaryNS.STAIN)

  const stains = useMemo(() => {
    if (!dictionaryLocales) return []
    return [
      { text: t('без окраски'), value: MINUS },
      ...dictionaryLocales?.map((item) => ({
        text: item.name || '',
        value: item.shortName || '',
      })),
    ]
  }, [dictionaryLocales])

  const handleSearch = (selectedKeys: any, confirm: () => void, dataIndex: any) => {
    confirm()
  }

  const input = useRef<any>(null)

  const rowSelection = {
    getCheckboxProps: (record: any) => ({
      name: record.slideId,
    }),
    onChange: (selectedRowKeys: React.Key[], selectedRows: IAtlasSlide[]) => {
      setSelected(selectedRows)
    },
  }

  const downloadHandler = async (type: 'CONVERTED_BY_VIPS' | 'ORIGINAL') => {
    if (selected) {
      const res = await DownloadAtlasSlides(
        selected?.map((item) => ({
          caseId: item.slideId,
          slideId: item.slideId,
          type,
        })),
      )
      res?.map((item: { found: boolean; downloadURL: string | URL | undefined }) => {
        item.found && window.open(item.downloadURL)
      })
    }
  }
  const onChangePagination = (direction: 'next' | 'prev') => {
    dispatch(
      viewerPageSlice.actions.setAtlasFilters({
        ...atlasFilters,
        after: data?.nextCursor,
        before: data?.previousCursor,
        direction,
      }),
    )
  }

  const el = useRef(null)

  const SEPARATOR = ': '

  const statusRenderer = (status?: IAtlasValidationStatus) => {
    if (status === 'VALID') return <GoodStatus>{t('Хороший')}</GoodStatus>
    if (status === 'INVALID') return <BadStatus>{t('Плохой')}</BadStatus>
    return null
  }

  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
    >
      <ContentWrapper>
        <Content>
          {isAtlasDownloadAvailable && (
            <DownloadBlock>
              {selected && selected?.length > 0 ? (
                <DownloadSubBlock data-testid={'download-block'}>
                  {t('Выбрано')}
                  {SEPARATOR}
                  {selected.length}
                  <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                    <ButtonElement onClick={() => downloadHandler('CONVERTED_BY_VIPS')}>
                      {t('Скачать TIFF')}
                    </ButtonElement>
                    <ButtonElement onClick={() => downloadHandler('ORIGINAL')}>{t('Скачать Оригинал')}</ButtonElement>
                  </div>
                </DownloadSubBlock>
              ) : (
                data?.totalElements && <span>{`${data?.totalElements} ${t('слайдов')}`}</span>
              )}
            </DownloadBlock>
          )}
          <Actions>
            <IconButtonElement
              onClick={() => onChangePagination('prev')}
              icon={<IconElement name="arrowLeftSmall" />}
              disabled={data?.first}
            />
            <IconButtonElement
              onClick={() => onChangePagination('next')}
              icon={<IconElement name="arrowRightSmall" />}
              disabled={data?.last}
            />
          </Actions>
          <StyledTable
            data-testid={'atlas-table-common'}
            ref={el}
            rowSelection={
              isAtlasDownloadAvailable
                ? {
                    type: 'checkbox',
                    ...rowSelection,
                  }
                : undefined
            }
            dataSource={data?.content?.map((item) => ({ ...item, key: data?.content.indexOf(item) }))}
            loading={isFetching}
            scroll={{ y: `calc(100vh - 165px)` }}
            onChange={(pagination: any, filters: PaginatedAtlasSlidesFilters) => {
              // @ts-ignore
              const { stain, uploadedDate, ...rest } = filters
              dispatch(
                viewerPageSlice.actions.setAtlasFilters({
                  ...filters,
                  stainCode: (stain as string[]) || undefined,
                  uploadedDateFrom: uploadedDate && typeof uploadedDate[0] === 'string' ? uploadedDate[0] : undefined,
                  uploadedDateTo: uploadedDate && typeof uploadedDate[1] === 'string' ? uploadedDate[1] : undefined,
                  ...rest,
                }),
              )
            }}
            pagination={false}
            //scroll={{ y: '82vh' }}
          >
            <Column
              title={t('Слайд')}
              dataIndex="caption"
              key="caption"
              render={(caption: string, record: IAtlasSlide) => (
                <RouterLink to={`/atlas-viewer/${record.slideId}`}>{caption || record.slideId}</RouterLink>
              )}
            />
            <Column
              title={t('Дата загрузки')}
              dataIndex="uploadedDate"
              key="uploadedDate"
              render={dateRenderer}
              filterDropdown={({ confirm, selectedKeys, setSelectedKeys }) => (
                <DateTimeContainer>
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <DateRangeLabel>{t('От')}</DateRangeLabel>
                    <MaskedDatePickerElement
                      value={selectedKeys[0] ? new Date(selectedKeys[0]) : null}
                      onChange={(e) => {
                        const newKeys = [...selectedKeys]
                        newKeys[0] = e ? (new Date(e).toISOString() as any) : undefined
                        setSelectedKeys(newKeys)
                      }}
                      allowClear={true}
                    />
                  </div>
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <DateRangeLabel>{t('До')}</DateRangeLabel>
                    <MaskedDatePickerElement
                      value={selectedKeys[1] ? new Date(selectedKeys[1]) : null}
                      onChange={(e) => {
                        const newKeys = [...selectedKeys]
                        newKeys[1] = e ? (new Date(e).toISOString() as any) : undefined
                        setSelectedKeys(newKeys)
                      }}
                      allowClear={true}
                    />
                  </div>

                  <ButtonElement
                    onClick={() => {
                      dispatch(
                        viewerPageSlice.actions.setAtlasFilters({
                          ...atlasFilters,
                          uploadedDateFrom: typeof selectedKeys[0] === 'string' ? selectedKeys[0] : undefined,
                          uploadedDateTo: typeof selectedKeys[1] === 'string' ? selectedKeys[1] : undefined,
                        }),
                      )
                      confirm()
                    }}
                    icon={<IconElement name="search" />}
                    type="primary"
                  />
                </DateTimeContainer>
              )}
              filteredValue={
                atlasFilters?.uploadedDateFrom || atlasFilters?.uploadedDateTo
                  ? [atlasFilters?.uploadedDateFrom || false, atlasFilters.uploadedDateTo || false]
                  : undefined
              }
            />
            <Column title={t('Случай')} dataIndex="blockExternalId" key="caption" render={caseRenderer} />
            <Column title={t('Блок')} dataIndex="blockExternalId" key="blockExternalId" />
            <Column
              title={t('Окраска')}
              dataIndex="stain"
              key="stain"
              render={stainRenderer}
              filters={stains}
              filterSearch={true}
              filteredValue={atlasFilters?.stainCode}
            />
            <Column
              title={t('Клиент')}
              dataIndex="clientCode"
              key="clientCode"
              filterDropdown={({ confirm, selectedKeys, setSelectedKeys }) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8 }}>
                  <Input
                    ref={input}
                    placeholder={`${t(`Поиск`)}...`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, 'clientCode')}
                    style={{ display: 'block' }}
                  />
                  <ButtonElement
                    onClick={() => handleSearch(selectedKeys, confirm, 'clientCode')}
                    icon={<IconElement name="search" />}
                    style={{ marginLeft: 10, width: 40 }}
                    type="primary"
                  />
                </div>
              )}
              render={(text) => text}
              filteredValue={atlasFilters?.clientCode}
            />
            <Column
              title={t('Статус')}
              dataIndex="validationStatus"
              key="validationStatus"
              filters={[
                {
                  text: t('Хороший'),
                  value: 'VALID',
                },
                {
                  text: t('Плохой'),
                  value: 'INVALID',
                },
                {
                  text: t('Другой'),
                  value: 'NOT_VIEWED',
                },
              ]}
              render={statusRenderer}
              filteredValue={atlasFilters?.validationStatus}
            />
            <Column title={t('Дата валидации')} dataIndex="validationDate" key="validationDate" render={dateRenderer} />
            {width > 1100 && (
              <Column
                title={t('Превью')}
                dataIndex={['thumbnails', 'medium']}
                key="preview"
                render={(src, record: IAtlasSlide) => (
                  <StyledLink to={`/atlas-viewer/${record.slideId}`}>{previewPopupRenderer(src)}</StyledLink>
                )}
              />
            )}
            {width > 1100 && (
              <Column
                title={t('Лейбл')}
                dataIndex="slideId"
                key="slideId"
                render={(slideId: number) => (
                  <LabelImagePreviewPopup caseId={slideId} slideId={slideId} source={'ATLAS'} />
                )}
              />
            )}
          </StyledTable>
        </Content>
      </ContentWrapper>
    </ContentLayout>
  )
}

export default AtlasRoute
